var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.fileData.type !== _vm.nuvolosObjectTypes.FOLDER
      ? _c(
          "div",
          { staticStyle: { "min-width": "200px" } },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  "offset-x": "",
                  "offset-overflow": "",
                  right: "",
                  "open-on-hover": "",
                  disabled:
                    _vm.currentFileStatus(_vm.fileData) ===
                    _vm.fileStatusTypes.STATUS_UNAVAILABLE,
                  "close-delay": _vm.closeDelay,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "span",
                            _vm._g({ staticClass: "pr-3" }, on),
                            [
                              _vm.mergedOptions.icon
                                ? [
                                    _vm.currentFileStatus(_vm.fileData) ===
                                    _vm.fileStatusTypes.SAVED_AND_UNCHANGED
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              color: "success",
                                              small: "",
                                            },
                                          },
                                          [_vm._v(" fiber_manual_record ")]
                                        )
                                      : _vm.currentFileStatus(_vm.fileData) ===
                                        _vm.fileStatusTypes.SAVED_AND_CHANGED
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { color: "info", small: "" },
                                          },
                                          [_vm._v(" fiber_manual_record ")]
                                        )
                                      : _vm.currentFileStatus(_vm.fileData) ===
                                        _vm.fileStatusTypes.UNSAVED
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              color: "error",
                                              small: "",
                                            },
                                          },
                                          [_vm._v(" fiber_manual_record ")]
                                        )
                                      : _vm.currentFileStatus(_vm.fileData) ===
                                        _vm.fileStatusTypes.STATUS_UNAVAILABLE
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              color: "secondary",
                                              small: "",
                                            },
                                          },
                                          [_vm._v(" fiber_manual_record ")]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm.relativeFileModificationDate(
                                _vm.fileData.last_modified_timestamp
                              ) === _vm.fileData.last_modified_timestamp
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dateTimeToHuman")(
                                            _vm.fileData.last_modified_timestamp
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.relativeFileModificationDate(
                                          _vm.fileData.last_modified_timestamp
                                        )
                                      )
                                    ),
                                  ]),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4077177429
                ),
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "ma-0 pa-0" },
                      [
                        _vm.currentFileStatus(_vm.fileData) ===
                        _vm.fileStatusTypes.SAVED_AND_UNCHANGED
                          ? _c(
                              "v-alert",
                              {
                                staticClass: "ma-0 secondary--text",
                                attrs: {
                                  color: "success",
                                  border: "left",
                                  "colored-border": "",
                                  icon: "mdi-check-circle",
                                  "max-width": "350",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Unmodified since last snapshot")]
                                    ),
                                    _c("span", { staticClass: "subtitle-2" }, [
                                      _vm._v(
                                        "This file is in the last immutable snapshot, and has no modifications since then."
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm.currentFileStatus(_vm.fileData) ===
                            _vm.fileStatusTypes.SAVED_AND_CHANGED
                          ? _c(
                              "v-alert",
                              {
                                staticClass: "ma-0 secondary--text",
                                attrs: {
                                  color: "info",
                                  border: "left",
                                  "colored-border": "",
                                  icon: "info",
                                  "max-width": "350",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Modified since last snapshot")]
                                    ),
                                    _c("span", { staticClass: "subtitle-2" }, [
                                      _vm._v(
                                        " This file is in the last immutable snapshot, but its content or modification timestamp has changed. "
                                      ),
                                    ]),
                                    _c("v-divider", {
                                      staticClass: "mt-1",
                                      staticStyle: { opacity: "0.22" },
                                      attrs: { color: "primary" },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-wrap justify-end",
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "caption font-weight-bold",
                                            attrs: {
                                              text: "",
                                              small: "",
                                              color: "secondary",
                                              to: {
                                                name: "snapshot-add-snapshot",
                                                params: {
                                                  oid: _vm.$route.params.oid,
                                                  sid: _vm.$route.params.sid,
                                                  iid: _vm.$route.params.iid,
                                                  snid: _vm.$route.params.snid,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: { small: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-camera-plus-outline"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" Snapshot all objects "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm.currentFileStatus(_vm.fileData) ===
                            _vm.fileStatusTypes.UNSAVED
                          ? _c(
                              "v-alert",
                              {
                                staticClass: "ma-0 secondary--text",
                                attrs: {
                                  color: "error",
                                  border: "left",
                                  "colored-border": "",
                                  icon: "error",
                                  "max-width": "350",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Unsnapshotted file")]
                                    ),
                                    _c("span", { staticClass: "subtitle-2" }, [
                                      _vm._v(
                                        "The file is not in the last immutable snapshot."
                                      ),
                                    ]),
                                    _c("v-divider", {
                                      staticClass: "mt-1",
                                      staticStyle: { opacity: "0.22" },
                                      attrs: { color: "error" },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-end" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "caption font-weight-bold",
                                            attrs: {
                                              text: "",
                                              color: "secondary",
                                              to: {
                                                name: "snapshot-add-snapshot",
                                                params: {
                                                  oid: _vm.$route.params.oid,
                                                  sid: _vm.$route.params.sid,
                                                  iid: _vm.$route.params.iid,
                                                  snid: _vm.$route.params.snid,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: { small: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-camera-plus-outline"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" Snapshot all objects "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "d-flex align-center",
            staticStyle: { "min-width": "200px" },
          },
          [
            _c(
              "v-icon",
              {
                staticClass: "mr-1",
                attrs: { color: "transparent", small: "" },
              },
              [_vm._v("fiber_manual_record")]
            ),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("dateTimeToHuman")(
                    _vm.fileData.last_modified_timestamp
                  )
                )
              ),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }